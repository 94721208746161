<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-autocomplete 
          dense
          :loading="isLoadingNotaFiscal"
          v-model="fornecedorSelecionado"
          :items="fornecedores"
          item-value="id"
          item-text="nome_fantasia"
          outlined
          clearable
          :rules="[validaCampo]"
        >
          <template v-slot:label>
            <span>Fornecedor <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field dense label="Razão Social" v-model="fornecedor.razao_social" outlined  disabled></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field dense label="Nº Nota Fiscal / Recibo" v-model="nota_fiscal" outlined  :rules="[validaCampo]">
          <template v-slot:label>
            <span>Nº Nota Fiscal / Recibo <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field dense label="Data da Nota Fiscal" v-model="dataAquisicao" outlined type="date" :rules="[validaCampo]">
          <template v-slot:label>
            <span>Data da Nota Fiscal <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field dense label="Desconto Geral da Nota Fiscal" v-model="desconto" outlined type="number"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';

export default {
  name: "CriarNotaFiscal",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      fornecedores: [], // Lista de fornecedores
      fornecedor: { razao_social: "" }, // Detalhes do fornecedor selecionado
      fornecedorSelecionado: null, // ID do fornecedor selecionado
      nota_fiscal: "", // Número da nota fiscal
      dataAquisicao: "", // Data da nota fiscal
      valor_cobranca: "", // Valor de cobrança
      desconto: "", // Desconto geral
      isLoadingNotaFiscal: false, // Status de carregamento
    });
  },
  components: { ConfirmButton },
  methods: {
    async getFornecedor() {
      this.isLoadingNotaFiscal = true;
      try {
        const { data } = await api('fornecedores/fornecedores/');
        this.fornecedores = data
          .filter(item => item.ativo)
          .map(f => ({ id: f.id, nome_fantasia: f.nome_fantasia || "Sem Nome" }))
          .sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));
      } catch (error) {
        this.$toast.error(`Erro ao carregar fornecedores. ${error}`);
      } finally {
        this.isLoadingNotaFiscal = false;
      }
    },
    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      const fields = {
        fornecedor: this.fornecedorSelecionado,
        nota_fiscal: this.nota_fiscal,
        dataAquisicao: this.dataAquisicao,
        desconto: this.desconto ? this.desconto : 0,
        ativo: this.ativo,
      };

      this.onSubmit(fields);
    },
    validaCampo(value) {
      return !!value || 'Este campo é obrigatório.';
    }
  },
  watch: {
    fornecedorSelecionado(newVal) {
      const fornecedor = this.fornecedores.find(f => f.id === newVal);
      if (fornecedor) {
        this.fornecedor.razao_social = fornecedor.nome_fantasia;
      }
    },
  },
  mounted() {
    this.currentItem.ativo = Number(this.currentItem.ativo)
    this.getFornecedor();
  },
};
</script>
